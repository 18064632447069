<template>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <!--Generator: Xara Designer (www.xara.com), SVG filter version: 6.1.0.18-->
    <svg class="" stroke-width="0.501" stroke-linejoin="bevel" fill-rule="evenodd"
        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1" overflow="visible"
        viewBox="0 0 850.396 850.396">
        <g id="Document" font-family="Times New Roman" font-size="16" transform="scale(1 -1)">
            <g id="Spread" transform="translate(0 -850.396)">
                <g id="Layer 1">
                    <path d="M 177.118,561.83 L 177.118,208.784" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 423.382,562.858 L 423.382,208.806" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 688.791,562.688 L 688.791,209.166" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 178.391,562.701 L 689.003,562.701" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 39.741,217.566 L 686.255,217.566" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 803.851,649.466 L 803.851,278.751" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 292.89,650.211 L 803.86,650.211" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 54.509,362.531 L 175.163,362.531" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 47.741,359.338 L 47.741,210.626" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 67.879,333.003 L 158.044,333.003" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 67.879,301.756 L 158.044,301.756" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 67.879,272.138 L 158.044,272.138" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 177.012,380.838 L 688.791,380.838" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 48.843,360.332 L 110.037,401.889" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path d="M 111.478,401.889 L 174.98,401.889" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 688.791,240.086 L 706.298,229.119" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 688.791,562.701 L 803.164,634.054" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path d="M 688.791,380.838 L 803.164,452.191" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path d="M 689.478,218.626 L 803.851,289.979" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path d="M 423.382,578.858 L 537.755,650.211" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path d="M 178.526,578.858 L 292.899,650.211" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path d="M 67.879,244.86 L 158.044,244.86" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 177.118,539.003 L 200.816,562.701" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 399.684,562.701 L 423.382,539.003" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 177.118,241.264 L 200.816,217.566" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 399.684,217.566 L 423.382,241.264" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 665.093,562.688 L 688.791,538.99" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 423.382,539.16 L 447.08,562.858" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 665.093,217.566 L 688.791,241.264" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 423.382,241.264 L 447.08,217.566" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 792.002,626.054 L 803.851,611.947" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 688.791,548.396 L 708.439,574.959" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 788.153,280.186 L 803.851,307.189" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 177.012,578.688 L 687.624,578.688" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 177.118,569.677 L 687.73,569.677" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="square" stroke-miterlimit="79.8403193612775" />
                    <path d="M 688.791,577.677 L 803.164,649.03" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path
                        d="M 570.395,571.603 L 566.962,580.855 L 574.159,580.855 L 570.395,571.603 Z M 562.393,567.78 L 557.626,580.855 L 565.792,580.855 L 569.748,570.068 L 568.811,567.78 L 562.393,567.78 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 582.304,569.723 C 585.493,569.723 586.617,572.078 586.617,574.089 C 586.617,576.1 585.493,578.455 582.304,578.455 C 579.117,578.455 577.993,576.1 577.993,574.089 C 577.993,572.078 579.117,569.723 582.304,569.723 Z M 582.304,580.92 C 589.263,580.92 590.349,576.789 590.349,574.089 C 590.349,571.388 589.263,567.258 582.304,567.258 C 575.347,567.258 574.26,571.388 574.26,574.089 C 574.26,576.789 575.347,580.92 582.304,580.92 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 592.089,580.558 L 595.605,580.558 L 595.605,570.194 L 603.487,570.194 L 603.487,567.62 L 592.089,567.62 L 592.089,580.558 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 614.702,580.558 L 619.432,580.558 L 613.489,575.357 L 619.885,567.62 L 615.138,567.62 L 610.734,573.22 L 608.687,571.462 L 608.687,567.62 L 605.172,567.62 L 605.172,580.558 L 608.687,580.558 L 608.687,574.995 L 614.702,580.558 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 620.936,580.558 L 633.023,580.558 L 633.023,578.094 L 624.451,578.094 L 624.451,575.412 L 632.587,575.412 L 632.587,573.055 L 624.451,573.055 L 624.451,570.085 L 633.095,570.085 L 633.095,567.62 L 620.936,567.62 L 620.936,580.558 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 638.549,574.832 L 643.206,574.832 C 643.986,574.832 644.891,575.266 644.891,576.481 C 644.891,577.766 643.876,578.094 643.024,578.094 L 638.549,578.094 L 638.549,574.832 Z M 635.034,580.556 L 644.438,580.556 C 648.026,580.556 648.624,578.365 648.624,577.043 C 648.624,575.466 647.99,574.451 646.522,573.925 L 646.522,573.89 C 648.062,573.654 648.297,571.698 648.297,570.428 C 648.297,569.795 648.351,568.272 648.968,567.62 L 645.127,567.62 C 644.8,568.218 644.782,568.743 644.782,570.103 C 644.782,571.915 644.004,572.368 642.916,572.368 L 638.549,572.368 L 638.549,567.62 L 635.034,567.62 L 635.034,580.556 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 654.024,578.094 L 649.222,578.094 L 649.222,580.558 L 662.341,580.558 L 662.341,578.094 L 657.539,578.094 L 657.539,567.62 L 654.024,567.62 L 654.024,578.094 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />

                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {

}
</script>

<style>
</style>
