<template class="bg-volkert-blue">
  <div class="bg-volkert-blue">
    <div class="mx-auto flex justify-center content-center mt-24">
      <div class="mr-12 mt-12 mb-12 text-white">
        <div class="title">404</div>
        <div class="text">This is not the page you are looking for.</div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" class="h-28 w-28 mt-12 justify-center content-center text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>

    </div>
  </div>
</template>



<script>

</script>



<style>
/* div {
    margin-bottom: 10px;
  } */
.title {
  font-weight: light;
  font-size: 52px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.text {
  font-weight: light;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
