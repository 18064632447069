<template>
        <svg class="" :class="class" version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve" overflow="hidden">
                 <title id="">safety</title>

                <g class="" :class="class" clip-path="url(#clip0)" transform="translate(-682 -375)">
                        <path d="M709.819 383.09C706.062 382.79 702.56 381.076 700.018 378.294 699.539 377.739 698.701 377.677 698.146 378.156 698.096 378.198 698.05 378.244 698.008 378.294 695.466 381.076 691.964 382.79 688.207 383.09 687.509 383.155 686.977 383.743 686.982 384.445L686.982 387.863C686.982 395.296 691.709 402.095 698.273 406.302 698.724 406.592 699.302 406.592 699.753 406.302 706.316 402.094 711.043 395.296 711.043 387.863L711.043 384.445C711.049 383.743 710.517 383.155 709.819 383.09ZM708.741 387.863C708.741 393.778 705.218 399.745 699.316 403.828L699.013 404.037 698.711 403.828C692.808 399.744 689.284 393.776 689.284 387.863L689.284 385.295 689.732 385.224C693.067 384.697 696.171 383.194 698.653 380.904L699.013 380.572 699.373 380.904C701.854 383.194 704.959 384.697 708.294 385.224L708.741 385.295Z"
                                fill="#A6A6A6" />
                        <path d="M699.013 382.008C696.554 384.159 693.56 385.606 690.347 386.196L690.347 387.863C690.347 393.327 693.579 398.865 699.013 402.742 704.446 398.866 707.679 393.327 707.679 387.863L707.679 386.196C704.466 385.606 701.472 384.159 699.013 382.008ZM698.103 395.837 694.522 392.255 695.739 391.038 698.103 393.402 702.945 388.56 704.162 389.778Z"
                                fill="#A6A6A6" />
                </g>
        </svg>
</template>


<script>
export default {
        props: ['fillAll', 'line1', 'line2', 'line3', 'class']
}
</script>


<style>

</style>