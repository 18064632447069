<template>
        <svg class="" :class="class" version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve" overflow="hidden">
                <title id="">speed</title>

                <g :class="fillAll" clip-path="url(#clip0)" transform="translate(-114 -406)">
                        <path d="M143.962 424.275 142.156 421.194C141.519 420.131 140.492 419.423 139.323 419.175 139.429 418.077 139.252 416.767 138.827 415.421 137.8 412.481 134.329 410.958 134.329 410.958 134.329 410.958 133.94 411.667 133.585 412.729 134.683 413.331 135.852 414.217 136.631 415.527 137.34 416.767 137.8 418.006 137.942 419.104L137.198 419.104C137.021 418.112 136.631 416.979 135.994 415.881 134.435 413.154 130.717 412.375 130.717 412.375 130.717 412.375 129.548 415.987 131.106 418.715 131.283 419.033 131.496 419.352 131.708 419.671 130.752 419.317 129.76 419.14 128.698 419.14 125.333 419.14 122.429 421.052 121.012 423.815 120.8 423.744 120.588 423.708 120.375 423.708 118.817 423.708 117.542 424.983 117.542 426.542 117.542 428.029 118.71 429.269 120.162 429.375 120.588 431.748 122.004 433.767 123.952 435.042L131.354 435.042C131.956 435.042 132.417 434.581 132.417 433.979 132.417 433.377 131.956 432.917 131.354 432.917L128.131 432.917C129.902 432.385 131.177 430.756 131.177 428.844 131.177 426.506 129.265 424.594 126.927 424.594 126.537 424.594 126.183 424.629 125.829 424.735 125.546 424.806 125.262 424.629 125.192 424.346 125.121 424.062 125.298 423.779 125.581 423.708 126.042 423.602 126.502 423.531 126.963 423.531 129.902 423.531 132.275 425.904 132.275 428.844 132.275 430.119 131.815 431.288 131.071 432.208 131.744 432.208 132.488 431.819 133.267 431.288L133.904 434.192C133.975 434.688 134.4 435.042 134.896 435.042 134.967 435.042 135.038 435.042 135.108 435.006 135.569 434.9 135.887 434.51 135.923 434.05L135.958 434.192C136.1 434.688 136.525 435.042 137.021 435.042 137.092 435.042 137.163 435.042 137.233 435.006 137.8 434.865 138.154 434.298 138.048 433.731L136.95 428.631C137.694 428.242 138.402 427.958 139.146 427.958 140.315 427.958 141.483 427.817 142.617 427.533 143.325 427.356 143.892 426.825 144.104 426.152L144.14 426.046C144.352 425.444 144.281 424.806 143.962 424.275Z"
                                fill="#A6A6A6" />
                </g>
        </svg>

</template>


<script>
export default {
        props: ['fillAll', 'line1', 'line2', 'line3', 'class']
}
</script>

<style>

</style>