<template>
    <!-- Cookie-Free Zone -->
    <div v-show="elementVisible" id="cookie-free-zone" class="hideElement animate-fade transition duration-700 transition-opacity ease-in opacity-100 
    mx-auto max-w-xs fixed inset-x-0 bottom-0 mb-2 z-50 bg-green-700 shadow-xl p-2 text-slate-300 flex justify-center dark:bg-slate-800
    dark:text-slate-400 rounded-lg ">
        <div class="flex items-center gap-4">
            <svg class="h-10 w-10" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <path style="fill:#DC774E;" d="M210.348,0C94.174,0,0,94.174,0,210.348c0,81.616,46.474,152.373,114.407,187.246l283.187-283.187
          	        C362.721,46.474,291.964,0,210.348,0z" />
                <path style="fill:#F7CD95;" d="M397.593,114.407C465.526,149.29,512,220.036,512,301.652C512,417.826,417.826,512,301.652,512
          	        c-81.616,0-152.362-46.474-187.246-114.407c-14.764-28.762-23.102-61.384-23.102-95.941c0-116.173,94.174-210.348,210.348-210.348
          	        C336.21,91.305,368.831,99.643,397.593,114.407z" />
                <g>
                    <path style="fill:#8E4238;" d="M97.404,131.972c8.788,8.8,8.788,23.046,0,31.834c-8.788,8.788-23.034,8.788-31.823,0
	                 	s-8.788-23.034,0-31.834C74.369,123.184,88.615,123.184,97.404,131.972z" />
                    <path style="fill:#8E4238;" d="M378.824,371.318c12.423,0,22.505,10.071,22.505,22.505c0,12.434-10.082,22.505-22.505,22.505
	              	    c-12.434,0-22.506-10.071-22.506-22.505S366.389,371.318,378.824,371.318z" />
                    <path style="fill:#8E4238;" d="M340.823,175.183c24.857,0,45.011,20.154,45.011,45.011s-20.154,45.011-45.011,45.011
	          	        c-24.869,0-45.011-20.154-45.011-45.011S315.955,175.183,340.823,175.183z" />
                </g>
                <path style="fill:#B75548;" d="M172.718,230.659c12.434,0,22.505,10.071,22.505,22.506c0,12.434-10.071,22.505-22.505,22.505
	                    c-12.434,0-22.505-10.071-22.505-22.505C150.213,240.73,160.284,230.659,172.718,230.659z" />
                <circle style="fill:#8E4238;" cx="239.751" cy="383.1" r="45.011" />
                <path style="fill:#B75548;" d="M430.586,291.075c12.423,0,22.505,10.071,22.505,22.505c0,12.434-10.082,22.506-22.505,22.506
          	        c-12.434,0-22.505-10.071-22.505-22.506S418.152,291.075,430.586,291.075z" />
                <g>
                    <circle style="fill:#700D00;" cx="206.342" cy="61.384" r="8.44" />
                    <circle style="fill:#700D00;" cx="250.936" cy="220.194" r="8.44" />
                    <circle style="fill:#700D00;" cx="321.131" cy="326.33" r="8.44" />
                </g>
            </svg>
            <div>
                <strong class="">Cookie-Free Zone</strong>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            elementVisible: true,
        }
    },
    created() {
        setTimeout(() => document.getElementById('cookie-free-zone').style.opacity = 0, 3000)
    }
}

</script>

<style>
</style>
