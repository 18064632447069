<template>
    <div class="counter"> 
    <!-- {{counter}} -->
        {{ $store.state.counter }}
    </div>
    
</template>

<script>
export default {

}

</script>

<style>

</style>
