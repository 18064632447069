<template>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <!--Generator: Xara Designer (www.xara.com), SVG filter version: 6.1.0.18-->
  <svg class="mb-2" :class="class" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1772.000000 1772.000000"
    preserveAspectRatio="xMidYMid meet">
    <title id="">Schweißen</title>

    <g :class="fillAll" transform="translate(0.000000,1772.000000) scale(0.100000,-0.100000)" stroke="none">
      <path :class="line1" d=" M16500 15965 c-1648 -37 -1896 -44 -2060 -60 -1015 -98 -2022 -453 -2875 -1013 -779 -510
        -1385 -1161 -1746 -1872 -23 -47 -381 -794 -795 -1660 l-752 -1575 -524 -8 c-287 -5 -526 -12 -531 -16 -6 -6 -1660
        -5847 -1669 -5894 -3 -15 79 -50 527 -224 292 -113 534 -209 538 -213 4 -3 -69 -193 -162 -421 -186 -453 -195 -485
        -167 -581 58 -201 269 -298 452 -208 113 55 121 69 315 545 96 233 178 425 182 425 5 0 276 -104 603 -231 328 -127
        597 -229 598 -227 2 1 632 1196 1401 2653 768 1458 1414 2680 1433 2716 l36 67 -304 298 c-168 165 -338 331 -378
        369 l-73 70 720 1507 c395 828 732 1528 747 1555 326 566 997 1061 1799 1328 276 92 543 151 817 179 100 11 585 25
        1613 47 809 17 1471 32 1472 33 1 1 -9 492 -22 1091 -14 600 -25 1147 -25 1218 l0 127 -72 -1 c-40 -1 -534 -12
        -1098 -24z" />
      <path :class="line2" d="M672 10503 c-53 -26 -77 -68 -77 -138 l0 -60 2109 -3618 c1539 -2639
              2118 -3624 2140 -3640 19 -13 49 -22 85 -25 46 -3 62 0 90 20 78 53 100 131
              61 209 -29 55 -4164 7152 -4193 7196 -45 67 -142 92 -215 56z" />
      <path :class="line3" d="M16225 5706 c-33 -13 -1664 -693 -3625 -1510 -2867 -1196 -3570
              -1492 -3592 -1516 -15 -16 -32 -47 -39 -70 -29 -98 50 -200 156 -200 27 0
              1059 426 3648 1506 1986 828 3623 1514 3638 1525 30 21 59 84 59 127 -1 45
              -35 107 -72 130 -54 33 -104 36 -173 8z" />
      <path :class="line4" d="M619 5507 c-50 -34 -79 -82 -79 -134 0 -57 19 -93 72 -136 96 -77
              3879 -2808 3903 -2817 64 -24 147 1 189 57 27 37 33 120 11 166 -14 30 -331
              264 -1963 1444 -1070 774 -1959 1416 -1976 1425 -45 25 -116 23 -157 -5z" />
      <path :class="line5" d="M12695 2635 c-2013 -348 -3676 -638 -3697 -644 -45 -14 -95 -69 -104
              -116 -18 -100 54 -195 150 -195 60 0 7386 1269 7416 1285 51 26 75 72 75 142
              0 46 -5 68 -21 90 -24 33 -98 73 -134 72 -14 0 -1672 -286 -3685 -634z" />
      <path :class="line6" d="M558 2798 c-47 -12 -105 -74 -114 -123 -15 -79 36 -163 112 -184 54
              -16 3851 -591 3898 -591 83 0 156 75 156 160 0 66 -45 130 -104 150 -22 7
              -3044 468 -3886 593 -14 2 -42 0 -62 -5z" />
      <path :class="line7" d="M304 721 c-118 -19 -221 -96 -270 -204 -40 -88 -41 -196 -1 -282 55
              -117 150 -188 279 -205 46 -6 3231 -13 8588 -20 7343 -9 8522 -8 8563 4 105
              32 179 95 226 196 22 46 26 69 26 140 0 71 -4 94 -26 141 -36 76 -86 129 -161
              170 l-63 34 -1675 6 c-921 4 -4656 9 -8300 13 -3644 3 -6740 8 -6880 10 -140
              3 -278 1 -306 -3z" />
    </g>
  </svg>

</template>

<script>
export default {
  props: ['fillAll', 'line1', 'line2', 'line3', 'line4', 'line4', 'line5', 'line6', 'line7', 'class']
}
</script>

<style>
</style>
