<template>
        <svg class="" :class="class" version="1.0" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" overflow="hidden">
                <title id="">heavy_workload</title>

                <g :class="fillAll" clip-path="url(#clip0)" transform="translate(-113 -368)">
                        <path d="M114.417 385C114.417 385.779 115.054 386.417 115.833 386.417L115.833 383.583C115.054 383.583 114.417 384.221 114.417 385Z"
                                fill="#A6A6A6" />
                        <path d="M144.167 383.583 144.167 386.417C144.946 386.417 145.583 385.779 145.583 385 145.583 384.221 144.946 383.583 144.167 383.583Z"
                                fill="#A6A6A6" />
                        <path d="M118.312 379.688 117.958 379.688C117.179 379.688 116.542 380.325 116.542 381.104L116.542 388.896C116.542 389.675 117.179 390.312 117.958 390.312L118.312 390.312C119.092 390.312 119.729 389.675 119.729 388.896L119.729 381.104C119.729 380.325 119.092 379.688 118.312 379.688Z"
                                fill="#A6A6A6" />
                        <path d="M142.042 379.688 141.688 379.688C140.908 379.688 140.271 380.325 140.271 381.104L140.271 388.896C140.271 389.675 140.908 390.312 141.688 390.312L142.042 390.312C142.821 390.312 143.458 389.675 143.458 388.896L143.458 381.104C143.458 380.325 142.821 379.688 142.042 379.688Z"
                                fill="#A6A6A6" />
                        <path d="M138.146 376.5 137.438 376.5C136.658 376.5 136.021 377.138 136.021 377.917L136.021 382.875 123.979 382.875 123.979 377.917C123.979 377.138 123.342 376.5 122.562 376.5L121.854 376.5C121.075 376.5 120.438 377.138 120.438 377.917L120.438 392.083C120.438 392.862 121.075 393.5 121.854 393.5L122.562 393.5C123.342 393.5 123.979 392.862 123.979 392.083L123.979 387.125 136.021 387.125 136.021 392.083C136.021 392.862 136.658 393.5 137.438 393.5L138.146 393.5C138.925 393.5 139.562 392.862 139.562 392.083L139.562 377.917C139.562 377.138 138.925 376.5 138.146 376.5Z"
                                fill="#A6A6A6" />
                </g>
        </svg>
</template>


<script>
export default {
        props: ['fillAll', 'line1', 'line2', 'line3', 'class']
}
</script>

<style>

</style>