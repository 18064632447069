<template>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <!--Generator: Xara Designer (www.xara.com), SVG filter version: 6.1.0.18-->
    <svg class="" stroke-width="0.501" stroke-linejoin="bevel" fill-rule="evenodd"
        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1" overflow="visible"
        viewBox="0 0 850.396 850.396">
        <g id="Document" font-family="Times New Roman" font-size="16" transform="scale(1 -1)">
            <g id="Spread" transform="translate(0 -850.396)">
                <g id="Layer 1">
                    <path d="M 308.896,562.858 L 308.896,208.806" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 478.793,562.688 L 478.793,209.166" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 309.139,562.701 L 485.025,562.701" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 315.21,217.566 L 483.308,217.566" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 593.853,649.466 L 593.853,278.751" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 424.55,650.211 L 594.885,650.211" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 313.748,380.838 L 484.98,380.838" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 478.793,240.086 L 496.3,229.119" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 478.793,562.701 L 593.166,634.054" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path d="M 478.793,380.838 L 593.166,452.191" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path d="M 479.48,218.626 L 593.853,289.979" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path d="M 308.896,578.858 L 423.269,650.211" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path d="M 455.095,562.688 L 478.792,538.99" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 308.896,539.16 L 332.594,562.858" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 455.095,217.566 L 478.793,241.264" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 308.896,241.264 L 332.594,217.566" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 582.004,626.054 L 593.853,611.947" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 478.793,548.396 L 498.441,574.959" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 578.155,280.186 L 593.853,307.189" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 309.045,578.688 L 483.968,578.688" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="round" stroke-miterlimit="79.8403193612775" />
                    <path d="M 308.892,569.677 L 484.124,569.677" fill="none" stroke-width="16" stroke-linejoin="round"
                        stroke-linecap="square" stroke-miterlimit="79.8403193612775" />
                    <path d="M 478.793,577.677 L 593.166,649.03" fill="none" stroke-width="16" stroke-linecap="round"
                        stroke-miterlimit="79.8403193612775" />
                    <path
                        d="M 385.678,568.376 L 382.251,577.612 L 389.436,577.612 L 385.678,568.376 Z M 377.69,564.56 L 372.932,577.612 L 381.083,577.612 L 385.033,566.844 L 384.097,564.56 L 377.69,564.56 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 397.567,566.5 C 400.75,566.5 401.872,568.85 401.872,570.858 C 401.872,572.865 400.75,575.216 397.567,575.216 C 394.385,575.216 393.263,572.865 393.263,570.858 C 393.263,568.85 394.385,566.5 397.567,566.5 Z M 397.567,577.677 C 404.514,577.677 405.598,573.553 405.598,570.858 C 405.598,568.162 404.514,564.039 397.567,564.039 C 390.622,564.039 389.537,568.162 389.537,570.858 C 389.537,573.553 390.622,577.677 397.567,577.677 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 407.335,577.316 L 410.844,577.316 L 410.844,566.97 L 418.713,566.97 L 418.713,564.4 L 407.335,564.4 L 407.335,577.316 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 429.908,577.316 L 434.63,577.316 L 428.697,572.124 L 435.082,564.4 L 430.343,564.4 L 425.947,569.99 L 423.904,568.236 L 423.904,564.4 L 420.395,564.4 L 420.395,577.316 L 423.904,577.316 L 423.904,571.762 L 429.908,577.316 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 436.131,577.316 L 448.197,577.316 L 448.197,574.856 L 439.64,574.856 L 439.64,572.179 L 447.762,572.179 L 447.762,569.826 L 439.64,569.826 L 439.64,566.861 L 448.269,566.861 L 448.269,564.4 L 436.131,564.4 L 436.131,577.316 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 453.714,571.6 L 458.362,571.6 C 459.141,571.6 460.044,572.033 460.044,573.246 C 460.044,574.529 459.031,574.856 458.181,574.856 L 453.714,574.856 L 453.714,571.6 Z M 450.205,577.314 L 459.592,577.314 C 463.174,577.314 463.771,575.126 463.771,573.807 C 463.771,572.233 463.138,571.219 461.673,570.694 L 461.673,570.659 C 463.21,570.424 463.445,568.471 463.445,567.203 C 463.445,566.571 463.498,565.051 464.114,564.4 L 460.28,564.4 C 459.954,564.997 459.936,565.521 459.936,566.879 C 459.936,568.688 459.159,569.14 458.073,569.14 L 453.714,569.14 L 453.714,564.4 L 450.205,564.4 L 450.205,577.314 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />
                    <path
                        d="M 469.162,574.856 L 464.368,574.856 L 464.368,577.316 L 477.464,577.316 L 477.464,574.856 L 472.67,574.856 L 472.67,564.4 L 469.162,564.4 L 469.162,574.856 Z"
                        fill="#ffffff" stroke="none" stroke-width="0.031" fill-rule="nonzero" stroke-linejoin="miter"
                        marker-start="none" marker-end="none" />

                </g>
            </g>
        </g>
    </svg>

</template>

<script>
export default {

}
</script>

<style>
</style>
