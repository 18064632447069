<template>
        <svg class="" :class="class" version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve" overflow="hidden">
                <title id="">specialiced</title>

                <g :class="fillAll" clip-path="url(#clip0)" transform="translate(-119 -335)">
                        <path d="M131.226 356.311 131.226 338.4 133.358 340.532C133.636 340.81 134.087 340.81 134.365 340.532 134.643 340.255 134.643 339.804 134.365 339.526L131.011 336.174C130.746 335.896 130.306 335.885 130.028 336.15 130.02 336.158 130.012 336.166 130.004 336.174L126.653 339.526C126.375 339.79 126.364 340.23 126.629 340.508 126.636 340.516 126.644 340.524 126.653 340.532 126.917 340.81 127.357 340.821 127.635 340.556 127.643 340.548 127.651 340.54 127.659 340.532L129.79 338.401 129.79 356.311C129.79 356.708 130.112 357.03 130.509 357.03 130.906 357.03 131.227 356.708 131.227 356.311Z"
                                fill="#A6A6A6" />
                </g>
        </svg>
</template>


<script>
export default {
        props: ['fillAll', 'line1', 'line2', 'line3', 'class']
}
</script>

<style>

</style>