<template>
        <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
        <!--Generator: Xara Designer (www.xara.com), SVG filter version: 6.1.0.18-->
        <svg class="mb-2" :class="class"
                version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1134.000000 1134.000000"
                preserveAspectRatio="xMidYMid meet">
                <title id="">Handling</title>

                <g :class="fillAll" transform="translate(0.000000,1134.000000) scale(0.100000,-0.100000)" stroke="none">
                        <path :class="line1" d="M3748 10073 c-3 -698 -4 -1270 -2 -1272 3 -3 293 -7 646 -9 l641 -5
                                -5 -189 c-3 -103 -7 -191 -10 -193 -2 -3 -200 -8 -438 -12 l-434 -6 -1180
                                -1111 c-650 -610 -1180 -1115 -1178 -1121 6 -19 1115 -2310 1125 -2322 7 -9
                                45 -13 128 -13 l119 0 0 93 c-1 50 -7 551 -14 1112 l-14 1020 26 27 c15 14
                                340 330 722 702 l694 676 281 0 281 0 17 -29 c30 -50 108 -131 172 -177 132
                                -94 271 -137 435 -136 236 1 440 103 586 293 l45 59 327 0 327 0 730 -690 730
                                -689 6 -343 c3 -189 7 -684 8 -1101 l1 -758 83 3 82 3 551 1170 552 1170 -49
                                45 c-27 25 -550 514 -1162 1088 l-1112 1043 -482 -1 -483 -1 0 196 0 195 638
                                0 639 0 6 563 c4 309 7 880 7 1268 l0 706 -1267 6 c-698 4 -1606 7 -2019 7
                                l-751 0 -5 -1267z" />
                        <path :class="line2" d="M3255 5178 c-2 -7 -6 -1058 -7 -2336 l-3 -2322 2158 -2 c1186 -2
                                2345 -3 2575 -3 l417 0 6 2330 c3 1282 6 2331 5 2331 -1 1 -1159 4 -2574 8
                                -2069 5 -2573 4 -2577 -6z" />
                        <path :class="line3" d="M121 439 c-47 -24 -93 -80 -112 -136 -27 -84 15 -199 93 -251 41 -27
                                43 -27 272 -33 444 -13 10768 -20 10811 -8 81 23 155 124 155 213 0 87 -75
                                190 -155 213 -30 9 -1371 14 -5530 18 l-5490 7 -44 -23z" />
                </g>
        </svg>
</template>

<script>
export default {
  props: ['fillAll', 'line1', 'line2', 'line3', 'class']
}
</script>

<style>
</style>
