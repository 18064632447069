<template>
        <svg class="" :class="class" version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve" overflow="hidden">
                <title id="">allrounder</title>

                <g :class="fillAll" clip-path="url(#clip0)" transform="translate(-688 -334)">
                        <path d="M709.562 339.99C709.562 339.751 709.444 339.529 709.247 339.395L705.974 336.127C705.695 335.844 705.24 335.841 704.957 336.119 704.675 336.398 704.671 336.853 704.95 337.136 704.953 337.139 704.955 337.141 704.958 337.144L707.089 339.271 705.729 339.271C703.085 339.273 700.671 340.775 699.5 343.146 698.329 340.775 695.915 339.273 693.271 339.271L691.912 339.271 694.043 337.144C694.326 336.866 694.331 336.411 694.052 336.128 693.774 335.844 693.319 335.84 693.036 336.119 693.033 336.121 693.03 336.124 693.028 336.127L689.754 339.395C689.556 339.528 689.437 339.751 689.438 339.99 689.438 339.993 689.438 339.995 689.438 339.998 689.438 340.002 689.438 340.004 689.438 340.007 689.437 340.198 689.513 340.382 689.649 340.517L692.994 343.861C693.277 344.139 693.732 344.136 694.01 343.853 694.286 343.573 694.286 343.124 694.01 342.845L691.874 340.708 693.271 340.708C696.313 340.712 698.778 343.177 698.781 346.219L698.781 354.365C698.781 354.762 699.103 355.083 699.5 355.083L699.5 355.083C699.897 355.083 700.219 354.762 700.219 354.365L700.219 346.219C700.222 343.177 702.687 340.712 705.729 340.708L707.126 340.708 704.991 342.845C704.708 343.123 704.705 343.578 704.983 343.861 705.262 344.144 705.717 344.148 706 343.869 706.002 343.867 706.005 343.864 706.008 343.861L709.353 340.517C709.488 340.381 709.563 340.198 709.562 340.007 709.562 340.004 709.562 340.001 709.562 339.998 709.562 339.996 709.562 339.993 709.562 339.99Z"
                                fill="#A6A6A6" />
                </g>
        </svg>
</template>


<script>
export default {
        props: ['fillAll', 'line1', 'line2', 'line3', 'class']
}
</script>


<style>

</style>